import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const SolutionsPage = () => {
    return (
        <Layout pageTitle="PragICTS | ITOps | Service Delivery Model of PragICTS">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>Service Delivery Models<br/> By  <span> PragICTS</span></h2>
                    
                                <div className="horizonral-subtitle"><span>Service</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Service</div>
                        <div className="container main-about">
                            <p>Services of PragICTS are delivered through the following Service Delivery Models (SDM).  Each model provides for a specific scope for engagement with PragICTS based on the clients requirements and context.</p>

                        </div>
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={CognizantImage}>
                                      
                                            <div className="hidden-works-item-text">
                                                <h3>Fixed Price</h3>
                                                <p>A fixed price is given for the fullfilment of a service when there is clarity (at both macro and micro levels). <br/>Ideal for projects and services with a clearly defined  <br/>
                                              
                                                1. Scope<br/>
                                                2. Requirements<br/>
                                                3. Design & Architecture<br/>
                                                4. Technology stack<br/>
                                                5. Processes and workflows<br/>
                                                6. Dependencies<br/>
                                                7. Integration Points & Systems<br/>
                                                8. Timelines<br/>
                                                </p>
                                             
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                  
                                            </div>
                                   
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={EcommerceImage}>
                                        
                                            <div className="hidden-works-item-text">
                                                <h3>Utilization</h3>
                                                <p>Service is billed on utilization (time and material). Billing is calculated on<br/>
                                                1. Man hours expended<br/>
                                                2. Materials (infrastructure, cloud, software, etc.) used and consumed<br/><br/>
                                          
                                                
                                               Ideal for projects and services which has futher finalization on<br/>
                                                1. Scope<br/>
                                                2. Requirements<br/>
                                                3. Design & Architecture<br/>
                                                4. Technology stack<br/>
                                                5. Processes and workflows<br/>
                                                6. Dependencies<br/>
                                                7. Integration Points & Systems<br/>
                                                8. Timelines<br/>

                                                </p>


                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={PayImage}>
                     
                                            <div className="hidden-works-item-text">
                                                <h3>Dedicated Resourcing</h3>
                                                <p>Projects and services which require a dedicated setup on a long term basis. Resources are exclusively dedicated to the client. Level of PragICTS supervision required depends on the scope 
and the SLAs<br/>

                                            Ideal for projects and services which requires<br/>
                                                1. Total control of the resources<br/>
                                                2. Dedicated resourcing<br/>
                                                3. To function as an offshore extension to the client's on-premise team<br/>
                                                4. Knowledge and experience retention of the team<br/>
                                                5. Total compliance with client's technology and engineering environments, standards, training, etc.<br/>


</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                         
                                            </div>
                                  
                                    </div>
                                 
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={SmileyImage}>
                     
                                            <div className="hidden-works-item-text">
                                                <h3>Bespoke</h3>
                                                <p>A combination of the above to meet the exact requirements of a client. Articulated and finalized at the pre engagement stage.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                               
                                            </div>
                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage
